.map-container {
  background-color: white;
  height: 100vh;
  width: 100%;
}

.button-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

.city-label {
  writing-mode: horizontal-tb;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  padding: 2px 5px;
  border-radius: 3px;
}

.custom-tooltip {
  pointer-events: none;
  z-index: 1000;
}

.tooltip-content {
  background: white;
  padding: 2px 5px;
  border-radius: 3px;
  text-align: center;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
